<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col cols="12" md="4" class="d-flex flex-column justify-center">
          <img
            width="100%"
            src="../../assets/sobre_territorio_santa_rita_09.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista da fachada frontal e da lateral esquerda (observador
            posicionado de frente para o templo) da Igreja Matriz de N. Sra. de
            Nazaré. Foto: Plínio Cintra, 2019.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            title="Manifestações Culturais"
            titleColor="#000"
            borderColor="#e06919"
            class="mb-5"
          >
            <template v-slot:conteudo>
              <p>
                Destaca-se no distrito a
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_religiosidade_calendario' }"
                  >Festa da Padroeira Nossa Senhora de Nazaré</router-link
                >, celebrada na primeira semana de setembro, que e tem seu ponto
                alto no dia 8, com cortejo de Nossa Senhora de Nazaré,
                levantamento de mastro, procissão da Bandeira, missas, novenas,
                leilões, queima de fogos e toque dos sinos, além de shows,
                barraquinhas de comidas e bebidas.
              </p>
              <p>
                O Dia da Independência do Brasil é também especial, coincidindo
                com as comemorações do dia da padroeira. A Escola Municipal
                Sinhô Machado promove as tradicionais Paradas de 7 de setembro,
                com alunos e fanfarra desfilando pelo centro histórico.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Igreja Matriz de Nossa Senhora de Nazaré"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                O templo foi tombado pelo IPHAN em 1945. Os primeiros registros
                relativos à Igreja são de 1729, ano de sua benção, sendo
                construída por ordem do sargento-mor Paulo Rodrigues Durão.
                Ressalta-se que no livro de receita e despesa da Irmandade do
                Santíssimo Sacramento constam trabalhos de reforma, pintura e
                até mesmo de reedificação do templo a partir da segunda metade
                do século XVIII. A matriz apresenta sistema construtivo misto de
                madeira, adobe e taipa. Foi implantada de modo destacado ao
                fundo da Praça de Santa Rita Durão sobre um platô elevado,
                cercado por parede de pedra-seca onde se localizam duas bicas.
              </p>
              <p>
                Apresenta simplicidade arquitetônica com pequenos detalhes tanto
                externa quanto internamente. No exterior, destaca-se um óculo em
                seu frontão triangular, a presença de esferas e cata-ventos nos
                telhados das duas torres sineiras e a composição renascentista
                formada pelo nicho com a imagem de Nossa Senhora de Nazaré sobre
                a porta da da edificação.
              </p>
            </template>
          </Card>

          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Já no interior da Igreja, destacam-se o altar-mor confeccionado
                em estilo D. João V, além dos retábulos do arco-cruzeiro, dos
                quatro retábulos laterais de estilo rococó, um par de esculturas
                de anjos em madeira esculpidos em escala humana e a pintura dos
                forros da nave e da capela-mor atribuída a João Batista de
                Figueiredo, considerado um dos mais importantes pintores de
                Minas Gerais no século XVIII e um dos precursores do estilo
                rococó.
              </p>
            </template>
          </Card>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_santa_rita_10.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista aproximada da porta da da Igreja Matriz de N. Sra. de Nazaré.
            Foto: Plínio Cintra, 2019.
          </p>
          <img
            width="100%"
            src="../../assets/sobre_territorio_santa_rita_11.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista dos fundos e da lateral direita da Igreja Matriz de N. Sra. de
            Nazaré. Foto: Plínio Cintra, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-start">
        <v-col cols="12" md="3" class="d-flex flex-column">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_santa_rita_12.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista da fachada frontal da Igreja Matriz de N. Sra. de Nazaré.
            Foto: Plínio Cintra, 2019.
          </p>
        </v-col>

        <v-col cols="12" md="3" class="d-flex flex-column">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_santa_rita_13.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista interna da Igreja Matriz de N. Sra. de Nazaré, onde se
            observam dois retábulos na lateral esquerda, com nichos das imagens
            de Santos. No canto inferior direito da imagem, visualiza-se o anjo
            esculpido em escala humana. Foto: Plínio Cintra, 2019.
          </p>
        </v-col>

        <v-col cols="12" md="3" class="d-flex flex-column">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_santa_rita_14.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista do interior da Igreja Matriz de N. Sra. de Nazaré, com bancos
            de madeira, retábulos laterais e uma imagem de Jesus Cristo
            carregando a cruz do calvário. Foto: Plínio Cintra, 2019.
          </p>
        </v-col>

        <v-col cols="12" md="3" class="d-flex flex-column">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_santa_rita_15.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista do altar-mor da Igreja Matriz de N. Sra. de Nazaré, no estilo
            D. João V. Foto: Plínio Cintra, 2019.
          </p>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="3" class="d-flex flex-column">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_santa_rita_16.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista interna da Igreja Matriz de N. Sra. de Nazaré, com destaque
            para a pintura dos forros da nave no estilo rococó. Foto: Plínio
            Cintra, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_santa_rita_17.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista da fachada frontal e lateral direita da Igreja de Nossa
            Senhora do Rosário. Fonte: ETII/IPHAN/Mariana, fevereiro de 2021.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Igreja de Nossa Senhora do Rosário"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Tombada pelo IPHAN em 1945, a Capela de Nossa Senhora do Rosário
                foi construída por volta da segunda metade do século XVIII.
                Apresenta estrutura autônoma de madeira e fechamento por adobe e
                pau a pique. Destaca-se pelo grande contraste entre a
                simplicidade de seu exterior e a riqueza e sofisticação rococó
                de seu interior, ornamentado por elementos decorativos e pela
                pintura dos forros de autoria de João Batista Figueiredo. Os
                retábulos também são caracterizados por trabalho requintado,
                sendo um deles o altar colateral à direita do altar-mor, de
                autoria atribuída a Antônio Francisco Lisboa, o Aleijadinho,
                considerado o maior artista brasileiro da época. Há, ainda, uma
                varanda nos fundos desse templo, elemento que não era comum no
                período colonial.
              </p>
              <p>
                As Igrejas dedicadas a Nossa Senhora do Rosário dos Homens
                Pretos, padroeira dos homens pretos, caracterizam-se como os
                poucos exemplares arquitetônicos do período colonial destinados
                especificamente ao segmento social formado por pessoas negras,
                tornando-se assim seu local de convívio social.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_santa_rita_18.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista interna da Igreja de Nossa Senhora do Rosário. Fonte:
            ETII/IPHAN/Mariana, 2021.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Os templos dedicados a Nossa Senhora do Rosário tornaram-se
                microterritórios das associações negras. Se, por um lado,
                ampliaram a segregação socioespacial existente na época, por
                outro, contribuíram significativamente com a autovalorização dos
                negros que, por meio das irmandades e outras sociedades de apoio
                mútuo, criavam estratégias para sobrevivência em meio à
                sociedade racista e escravocrata da época. Além disso, era
                possível se reagrupar e se reestruturar oficialmente mesmo que
                no refúgio da devoção, rememorando sua cultura e seus reinados
                existentes na terra natal e revividos no entorno das Igrejas do
                Rosário por meio da (re)eleição de reis e rainhas em seus
                eventos de dança-cantada como a Congada, o Maculelê, o Jongo,
                entre outros.
              </p>
              <p>
                Os negros, africanos escravizados ou forros, se associavam às
                Irmandades de Nossa Senhora do Rosário, São Benedito e Santa
                Efigênia. O negro nascido no Brasil com mãe de origem africana,
                nascendo escravo quando filho de mãe escrava e livre quando
                filho de mãe forra, era referenciado no Setecentos mineiro como
                crioulo. Estes crioulos se associavam à Irmandade de Nossa
                Senhora das Mercês. A devoção a Nossa Senhora das Mercês está
                relacionada ao fato dela conceder liberdade aos cativos. O
                perfil racial das comunidades atingidas é majoritariamente
                negro, isso se expressa também nas devoções. Na vizinha
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'bento_rodrigues' }"
                >
                  Bento Rodrigues</router-link
                >, há uma capela em devoção a Nossa Senhora das Mercês e, na
                sede de Mariana, a Igreja da mesma irmandade. Em
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_ponte_do_gama' }"
                  >Ponte do Gama</router-link
                >
                e em
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_campinas' }"
                >
                  Campinas</router-link
                >, as capelas foram construídas em louvor a Nossa Senhora
                Aparecida, a representação negra de Maria.
              </p>
              <p style="text-align: center">
                <strong>Autoria: Ana Paula Alves Ferreira<sup>1</sup> </strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  margin-top: 2em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
